import {IconName} from '../components/KIcon/KIcon';

export const amenities: {[key: string]: IconName} = {
  // Place
  Garden: 'garden',
  Balcony: 'balcony',
  Terrace: 'terrace',
  'Ground floor': 'groundFloor',
  Rooftop: 'rooftop',
  // Temp control
  Heating: 'heating',
  'A/C': 'ac',
  //Kitchen
  Refrigerator: 'refrigerator',
  'Coffee machine': 'coffeeMachine',
  Microwave: 'microwave',
  Oven: 'oven',
  Barbecue: 'barbecue',
  Dishwasher: 'dishwasher',
  // Clothes
  Iron: 'iron',
  // 'Washing machine': 'washingMachine',
  // Dryer: 'dryer', ==> No dryer expected
  'Closet space': 'closetSpace',
  // Stuff
  Crib: 'crib',
  'Hair dryer': 'hairDryer',
  TV: 'tv',
  Fireplace: 'fireplace',
  Desk: 'desk',
  'Wi-fi': 'wifi',
  // Outside
  'Parking spot': 'parkingSpot',
  // Jacuzzi: 'jacuzzi',
  'Swimming pool': 'pool',
  // 'Wheelchair accessible': 'WheelchairAccessible',
};

// missing icon from figma

// washing machine

// wheelchair accessible
// jacuzzi
