const variables = {
  spacing: {
    xxxsmall: 2,
    xxsmall: 5,
    xsmall: 10,
    small: 20,
    medium: 30,
    large: 40,
    xlarge: 100,
  },
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    blackLight: '#1E1E1E',
    yellow: '#ffe361',
    greenLight: '#f7f6e9',
    closeButton: '#7f7230',
    borderGray: '#C6C5BA',
    lightGray: '#aaaaaa',
    xLightGray: '#cccccc',
    xxLightGray: '#eeeeee',
    grey: '#999',
    darkYellow: '#EBE9D8',
    green: '#84B975',
    orange: '#FF784E',
  },
  font: {
    size: {},
    weight: {},
    colors: {},
    family: {
      regular: 'Plus Jakarta Sans',
      bold: 'Montserrat-Bold',
      semiBold: 'Montserrat-SemiBold',
      medium: 'Montserrat-Medium',
      light: 'Montserrat-Light',
      extraLight: 'Montserrat-ExtraLight',
      thin: 'Montserrat-Thin',
    },
  },
  form: {
    colors: {
      border: {
        default: '#C6C5BA',
        focus: '#000000',
        error: '#FF784E',
      },
      background: {
        default: '#FFFFFF',
        error: '#FF784E',
      },
      placeholder: '#808080',
    },
    input: {
      borderRadius: 30,
      borderWidth: 1,
      padding: 15,
      paddingLeft: 35,
      fontSize: 15,
      lineHeight: 20,
    },
  },
  button: {
    size: {
      xxsmall: {
        width: 40,
        height: 40,
      },
      xsmall: {
        width: 80,
        height: 40,
      },
      small: {
        width: 44,
        height: 44,
      },
      medium: {
        width: 150,
        height: 44,
      },
      large: {
        width: 340,
        height: 44,
      },
    },
    padding: 15,
    borderRadius: 30,
  },
  circleImage: {
    size: {
      xxsmall: {
        width: 40,
        height: 40,
      },
      xsmall: {
        width: 60,
        height: 60,
      },
      small: {
        width: 100,
        height: 100,
      },
      medium: {
        width: 140,
        height: 140,
      },
      large: {
        width: 170,
        height: 170,
      },
      xlarge: {
        width: 200,
        height: 200,
      },
    },
  },
  images: {
    defaultPics: '/defaultPics.svg',
    defaultImage: '/defaultImage.svg',
  },
  icon: {
    size: {
      xxsmall: 3,
      xsmall: 12,
      small: 16,
      medium: 24,
      large: 32,
      xlarge: 40,
      xxlarge: 45,
    },
  },
  layoutMaxWidth: 1500,
  propertyCardWidth: 350,
};

export default variables;
